// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import BlogEntryBodyText from '~components/blog/BlogEntryBodyText';
import BlogEntryBodyImage from '~components/blog/BlogEntryBodyImage';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import NodeBody from '~plugins/prismic/components/NodeBody';
import NodeImage from '~plugins/prismic/components/NodeImage';
import type { PrismicBlogEntry } from '~schema';
import newDate from '~helpers/newDate';

export type ClassKey = 'root' | 'imageSection' | 'image' | 'languagePicker';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicBlogEntry>>,
};

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: '100%',
  },
  imageSection: {
    width: '100%',
    position: 'relative',
  },
  image: {
    height: '35vh',
    [theme.breakpoints.up('lg')]: {
      height: '65vh',
    },
  },
  languagePicker: {
    display: 'inline-flex',
  },
});

const BlogEntryPage = ({ node, classes }: Props) => {
  if (!node) {
    return null;
  }

  const publication_date = newDate(node.first_publication_date);

  return (
    <div className={classes.root}>
      <div className={classes.imageSection}>
        <NodeImage data={node.data?.header_image} className={classes.image} />
      </div>
      <Container component={ResPadding} vertical>
        {node.data?.title?.text ? (
          <Typography variant="h4" align="left">
            {node.data?.title?.text}
          </Typography>
        ) : null}
        {publication_date ? (
          <Typography variant="subtitle1" color="primary">
            {publication_date.toString()}
          </Typography>
        ) : null}
        <NodeBody
          node={node}
          components={{
            text: BlogEntryBodyText,
            image: BlogEntryBodyImage,
          }}
        />
      </Container>
    </div>
  );
};

export default compose(
  withNodePage<PrismicBlogEntry, *>({
    getNode: data => data?.prismicBlogEntry,
  }),
  withStyles<ClassKey, *, Props>(styles, { name: 'BlogEntryPage' }),
)(BlogEntryPage);

export const query = graphql`
  query PrismicBlogEntry($prismicId: ID) {
    prismicBlogEntry(prismicId: { eq: $prismicId }) {
      id
      lang
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        title {
          text
        }
        description {
          text
          html
        }
        header_image {
          alt
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
        }
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                name {
                  text
                }
                email {
                  text
                }
              }
            }
          }
        }
        body {
          ... on Node {
            id
          }
          ... on PrismicSliceType {
            slice_type
          }
          ... on PrismicBlogEntryBodyText {
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              text {
                html
              }
            }
          }
          ... on PrismicBlogEntryBodyImage {
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              simple_image {
                alt
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
