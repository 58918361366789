// @flow

import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import type { WithStyles } from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';
import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';
import type { PrismicBlogEntryBodyText } from '~schema';

export type ClassKey = 'root';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  data: PrismicBlogEntryBodyText,
  className?: string,
};

const styles = (theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
});

const BlogEntryBodyText = ({ className, classes, data }: Props) => (
  <RichText
    {...(data.primary?.text || {})}
    className={classnames(className, classes.root)}
  />
);

BlogEntryBodyText.defaultProps = {
  className: undefined,
};

export default withStyles<ClassKey, *, Props>(styles, { name: 'BlogEntryBodyText' })(BlogEntryBodyText);
