// @flow
import * as React from 'react';

import NodeImage from '~plugins/prismic/components/NodeImage';
import type { PrismicBlogEntryBodyImage } from '~schema';

type Props = {
  data: PrismicBlogEntryBodyImage,
};

const BlogEntryBodyImage = ({ data }: Props) =>
  data.primary && data.primary.simple_image ? (
    <NodeImage data={data.primary.simple_image} />
  ) : null;

export default BlogEntryBodyImage;
